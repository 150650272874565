import React from "react";
import styles from "./List.module.scss";

export default function ListInteractions() {
  return (
    <>
      <h2>Interactions</h2>
    </>
  );
}
