import React from "react";
import styles from "./Create.module.scss";

export default function CreateHealthWorker() {
  return (
    <>
      <h2>Health Workers</h2>
    </>
  );
}
