import React from "react";
import styles from "./Create.module.scss";

export default function CreateInteraction() {
  return (
    <>
      <p>Create Interaction here</p>
    </>
  );
}
