import React from "react";
import styles from "./List.module.scss";

export default function ListHealthWorkers() {
  return (
    <>
      <h2>Health Workers</h2>
    </>
  );
}
