import React from "react";
// import styles from "./List.module.scss";

export default function ListPatients() {
  return (
    <>
      <h2>Patients</h2>
    </>
  );
}
