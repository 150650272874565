import React from "react";
// import styles from "./List.module.scss";

export default function ListPractitioners() {
  return (
    <>
      <h2>Practitioners</h2>
    </>
  );
}
